import React, { useEffect } from "react"
import Image from "../components/image"
import SEO from "../components/seo"
import AniLinkWrapper from "../components/aniLinkWrapper"

const IndexPage = () => {
  useEffect(() => {
    // redirect
    window.location.href = "//buffy.co"
  }, [])
  return (
    <>
      <SEO title="Home" />
      <AniLinkWrapper to="/products/">
        <div style={{ marginBottom: `1.45rem` }}>
          <Image />
        </div>
      </AniLinkWrapper>
      <AniLinkWrapper to="/products/">Products</AniLinkWrapper> |{" "}
      <AniLinkWrapper to="/bedbuilder/">Bed Builder</AniLinkWrapper>
    </>
  )
}

export default IndexPage
